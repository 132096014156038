import React from 'react'
import { withPrefix } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import { BigPlayButton, ControlBar, Player } from 'video-react'

const HowToPlay = () => (
  <Layout>
    <Helmet>
      <body className="is-video" />
    </Helmet>
    <SEO title="How To Play" />
    <div className="c-video">
      <div className="container">
        <div className="row">
          <div className="c-video__container">
            <div className="c-video__video">
              <Player poster={
                '/videos/how_to_play.jpg'
              }>
                <source src={ withPrefix('/videos/how_to_play.mp4') }/>
                <ControlBar disableCompletely={ true }/>
                <BigPlayButton position="center"/>
              </Player>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default HowToPlay
